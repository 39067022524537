<template>
  <div
    class="model-intro model-intro__planA"
    aria-hidden="true"
  >
    <div
      v-if="showModel"
      class="model-intro__wrap"
      @click="clickModelIntro"
    >
      <div class="model-intro__info">
        <div class="model-intro__image">
          <img
            :src="LAZY_IMG_SQUARE"
            :data-src="thumbnailImg(modelInfo.image, '220x')"
            data-design-width="100"
            class="lazy lazyload"
          />
        </div>
        <ul class="model-intro__size">
          <div
            v-if="modelWearingSizeShow"
            class="model-intro__modelSize"
          >
            <span class="key">{{ modelInfo.size ? language.SHEIN_KEY_PWA_20169 + ":" : "" }}</span>
            <span class="value">{{ modelInfo.size }}{{ modelLocalSize }}</span>
            <i
              v-if="isClick"
              v-expose="{ id: '1-6-1-137', data: { from: 5 } }"
              class="suiiconfont sui_icon_more_right2_16px"
            ></i>
          </div>
          <template v-if="!showFootInfo">
            <!-- <template v-if="!sizeGuidPlanA">
              <template v-for="(item, key) in modelInfo.attrI18n">
                <template v-if="key == 'Height'">
                  <li :key="key">
                    <span class="key">{{ item.label }}</span>
                    <span class="value">{{ item.value }}</span>
                  </li>
                </template>
                <template v-if="key == 'Bust'">
                  <li :key="key">
                    <span class="key">{{ item.value ? language.SHEIN_KEY_PWA_16221 : "" }}</span>
                    <span class="value">{{ item.value }}</span>
                  </li>
                </template>
                <template v-if="key == 'Waist'">
                  <li :key="key">
                    <span class="key">{{ item.label }}</span>
                    <span class="value">{{ item.value }}</span>
                  </li>
                </template>
                <template v-if="key == 'Hip'">
                  <li :key="key">
                    <span class="key">{{ item.label }}</span>
                    <span class="value">{{ item.value }}</span>
                  </li>
                </template>
              </template>
            </template> -->
            <span 
              class="model-intro__planA-content"
            >
              {{ modelInfoValueStr }}
              <!-- <template 
                v-for="(item, key) in modelInfo.attrI18n"
              >
                <template v-if="key == 'Height'">
                  {{ item.label }}&nbsp;{{ item.value }},&nbsp;
                </template>
                <template v-if="key == 'Bust'">
                  {{ item.value ? language.SHEIN_KEY_PWA_16221 : "" }}&nbsp;{{ item.value }},&nbsp;
                </template>
                <template v-if="key == 'Waist'">
                  {{ item.label }}&nbsp;{{ item.value }},&nbsp;
                </template>
                <template v-if="key == 'Hip'">
                  {{ item.label }}&nbsp;{{ item.value }}
                </template>
              </template> -->
            </span>
          </template>
          <template v-else>
            <template v-for="(item, key) in modelInfo.attrI18n">
              <div
                v-if="key === 'FootLength' || key === 'BallGirth'"
                :key="key" 
                class="model-intro__planA-content"
              >
                <!-- <li
                  v-if="!sizeGuidPlanA"
                  :key="key"
                  class="line"
                > 
                  <span class="key">{{ item.label }}:</span>
                  <span class="value">{{ item.value }}</span>
                </li> -->
                {{ item.label }}:&nbsp;{{ item.value }}{{ key === 'BallGirth'?',':'' }}&nbsp;
              </div>
            </template>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
import { mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const { LAZY_IMG_SQUARE, WEB_CLIENT } = gbCommonInfo

export default {
  name: 'ModelIntro',
  props: {
    // sizeGuidPlanA: {
    //   type: Boolean,
    //   default: false
    // },
    isClick: {
      type: Boolean,
      default: false
    },
    // unitType: {
    //   type: String,
    //   default: 'cm'
    // },
    language: {
      type: Object,
      default: () => ({})
    },
    // parentCats: {
    //   type: Object,
    //   default: () => ({})
    // },
    moduleRenderConfig: {
      type: Array,
      default: () => []
    },
    localsize: {
      type: [Object, Array],
      default () {
        return {} || []
      }
    },
    modelContent: {
      type: Object,
      default: () => ({})
    },
    currentLocalCountry: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      LAZY_IMG_SQUARE
    }
  },
  computed: {
    ...mapGetters('productDetail/common', ['skc_sale_attr', 'cate_infos']),
    modelInfoValueStr() {
      let str = ''
      for (let key in this.modelInfo.attrI18n) {
        str += `${this.modelInfo.attrI18n[key].label} ${this.modelInfo.attrI18n[key].value}, `
      }

      return str.slice(0, -2)
    },
    showModel() {
      if (!Object.keys(this.modelInfo).length) {
        this.$emit('handleShowModel', false)
        return false
      }
      if (this.showFootInfo) {
        const showFoot = 
        !!(
          this.modelInfo.attr.FootLength && this.modelInfo.attr.BallGirth
        )
        this.$emit('handleShowModel', showFoot)
        return showFoot
      }
      this.$emit('handleShowModel', true)
      return true
    },
    // 模特相关信息是否展示
    modelRelativesConfig() {
      const { modelContent } = this
      const config = {
        ...modelContent.attr,
        size: modelContent.size
      }
      return config
    },
    // 模特穿着尺码是否展示
    modelWearingSizeShow() {
      const { modelRelativesConfig } = this
      if (modelRelativesConfig.size === false) {
        return false
      }
      return true
    },
    modelInfo() {
      const { modelRelativesConfig, filterSize } = this
      if (!this.modelContent?.attr) return {}
      const modelContent = {
        attrI18n: {}
      }
      const attrDic = {
        Height: this.language.SHEIN_KEY_PWA_14993,
        Bust: this.language.SHEIN_KEY_PWA_16221 || this.language.SHEIN_KEY_PWA_14996,
        Waist: this.language.SHEIN_KEY_PWA_14997,
        Hip: this.language.SHEIN_KEY_PWA_14998,
        BallGirth: this.language.SHEIN_KEY_PWA_18331,
        FootLength: this.language.SHEIN_KEY_PWA_18330
      }
      Object.keys(this.modelContent.attrcm).forEach(key => {
        if (attrDic[key] && modelRelativesConfig[key] !== false && (filterSize(this.modelContent.attrcm[key]) || filterSize(this.modelContent.attrinch[key]))) {
          const valuePlanA =  this.modelContent.attr[key]
          // const value = this.sizeGuidPlanA ? valuePlanA :  this.modelContent.attrcm[key] + ' / ' + this.modelContent.attrinch[key]
          modelContent.attrI18n[key] = {
            label: attrDic[key],
            value: valuePlanA
          }
        }
      })
      Object.assign(modelContent, this.modelContent)
      return modelContent
    },
    showFootInfo() {
      if (WEB_CLIENT === 'romwe') return false
      return this.cate_infos[1]?.mall_category_id == '1745'
    },
    modelLocalSize() {
      const { size } = this.modelContent
      const attr_value_local_size = this.skc_sale_attr[0]?.attr_value_list?.find(i => i.attr_value_name_en === size)?.attr_value_local_size ?? []
      const attr_local_size_value = attr_value_local_size.find(i => i.country_code === this.currentLocalCountry)?.attr_local_size_value

      return attr_local_size_value ? `(${this.currentLocalCountry}: ${attr_local_size_value})` : ''
    }
  },
  mounted() {
    this.$emit('lazyLoaded')
  },
  methods: {
    thumbnailImg(fileUrl, size) {
      let fileName = this.transformImg({ img: fileUrl })
      let suffix = `.${fileName.split('.').pop()}`
      return fileName.replace(suffix, `_thumbnail_${size}${suffix}`)
    },
    transformImg,
    // 过滤掉空白尺码
    filterSize(size) {
      return ![0, 0.0, '0', '0.0', ''].includes(size)
    },
    clickModelIntro() {
      if(!this.isClick) return
      this.$emit('clickModelIntro')
      daEventCenter.triggerNotice({ daId: '1-6-1-138', extraData: { from: 5 } })
    }
  }
}
</script>

<style lang="less">
.model-intro {
  // background-color: #f6f6f6;
  &__sizeChart{
    padding: .32rem 0 .16rem;
    text-align: left;
    position: relative;
    text-transform: capitalize;
    display: block;
    width: 100%;
    font-size: 0.3733rem;
  }
  &__wrap {
    padding: 0 0 20/75rem;
    background: #fff;
  }
  &__title {
    margin-bottom: 0.37rem;
    line-height: 0.51rem;
    font-weight: bold;
    .font-dpr(28px);
  }
  &__info {
    .flexbox;
    width: 100%;
  }
  &__image {
    margin-right: 0.32rem;
    overflow: hidden;
    /*sh:begin*/
    width: 1.28rem;
    height: 1.28rem;
    border-radius: 50%;
    /*rw:begin*/
    width: 1.87rem;
    height: 1.87rem;
    img {
      /*sh:begin*/
      width: 100%;
      /*rw:begin*/
      display: inline-block;
      width: 1.87rem;
    }
  }
  &__planA{
    .model-intro__modelSize{
      color: var(---sui_color_gray_dark1, #222);
      font-size: 0.3733rem;
    }
    .model-intro__image{
      width: 1.1733rem;
      height: 1.1733rem;
    }
    .model-intro__info {
      align-items: center;
    }
  }
  &__planA-content{
    color: var(---sui_color_gray_dark3, #767676);
    display: -webkit-box;
    font-size: 0.32rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__size {
    .flexbox;
    flex: 1;
    flex-wrap: wrap;
    li {
      padding-right: 0.4rem /*rtl:ignore*/;
      min-width: 2.4rem;
      color: #767676;
      .font-dpr(24px);
      margin: 0 0 0.107rem;
      line-height: 1.2;
      &.line {
        width: 100%;
        padding-right: 0;
      }
      // &:last-child {
      //   margin: 0;
      // }
    }
  }
  &__modelSize {
    width: 100%;
    color: #767676;
    .font-dpr(24px);
    margin: 0 0 0.107rem;
    line-height: 1.2;
  }
  &__alone {
    border-top: 1px solid #e5e5e5;
    .model-intro__wrap{
      padding-top: 0.32rem;
      display: flex;
      .model-intro__modelSize{
        position: relative;
        .sui_icon_more_right2_16px {
          position: absolute;
          color: #959595;
          .right(0);
        }
      }
    }
  }
}
</style>
